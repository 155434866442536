import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();

  // Define sections without subsections
  const sections = Array.from({ length: 15 }, (_, i) => ({ id: i + 1 }));

  return (
    <div className="relative max-w-[1920px] h-auto w-screen overflow-hidden z-[30]">
      {/* Start and End Fade Out Overlays (Shared across Hero and Content) */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      {/* Terms and Conditions Hero Section */}
      <div className="relative h-auto w-full z-[30]">
        <div className="h-full w-full flex flex-col justify-center items-center text-center p-[6rem] xl:p-[18rem]"
          style={{
            backgroundImage: 'url("/policy/policy-hero-bg.webp")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <h1 className="text-[#FFC85A] text-3xl lg:text-6xl xl:text-9xl font-normal font-bigNoodle animate-fadeIn mb-[1rem] xl:mb-[2rem]">
            {t("t&c title")}
          </h1>
          <p className="text-white text-base lg:text-lg xl:text-xl font-medium font-outfit px-[1rem] lg:px-[5rem] xl:px-[10rem] xl:mb-[2rem] animate-fadeIn">
            {t("t&c date")}
          </p>
        </div>
      </div>

      {/* Terms and Conditions Content Section */}
      <div className="relative h-full w-full mt-[-22rem] xl:mt-[-28rem] z-[10] text-justify">
        {/* Pseudo-element for background image with 10% opacity */}
        <div
          className="absolute inset-0 z-[-1] opacity-10"
          style={{
            backgroundImage: `url('/policy/policy-content-bg.webp')`,
            backgroundRepeat: 'repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
          }}
        ></div>

        {/* Gradient Background */}
        <div
          className="absolute inset-0 z-[-2]"
          style={{
            backgroundImage: `linear-gradient(to bottom, #003E6B, #000000)`,
          }}
        ></div>

        <div className="flex flex-col items-start px-[5rem] lg:px-[10rem] xl:px-[20rem] py-[22rem] z-[-1]">
          <div className="flex flex-col mb-[3rem]">
            <p
              className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide"
              dangerouslySetInnerHTML={{ __html: t("t&c introduction") }}
            />
          </div>
          {/* Content Sections */}
          {sections.map((section) => (
            <div key={section.id} className="flex flex-col mb-[4rem]">
              <h1 className="text-[#49DEFF] text-2xl lg:text-4xl xl:text-6xl font-normal font-bigNoodle mb-[1rem]">
                {section.id}. {t(`t&c ${section.id} title`)}
              </h1>
              <p
                className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide"
                dangerouslySetInnerHTML={{
                  __html: t(`t&c ${section.id} content`),
                }}
              />
            </div>
          ))}
          <div className="flex flex-col mb-[3rem]">
            <h1 className="text-[#49DEFF] text-2xl lg:text-4xl xl:text-6xl font-normal font-bigNoodle mb-[1rem]">
              16. {t("t&c 16 title")}
            </h1>
            <p className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide">
              {t("t&c 16 content")}
              <a href={`mailto:${t("email")}`} className="text-[#49DEFF] underline">
                {t("email")}
              </a>.
            </p>
            <br />
            <p
              className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide"
              dangerouslySetInnerHTML={{ __html: t("t&c footer") }}
            />
          </div>

        </div>

        {/* Footer Images */}
        <img
          className="absolute left-0 bottom-0 w-full lg:w-1/2 mb-[-1rem] md:mb-[-2.5rem] lg:mb-[-2rem] xl:mb-[-3rem]"
          src="/policy/policy-footer-left-img.webp"
          alt="Background"
        />

        <img
          className="absolute right-0 bottom-0 w-full lg:w-1/2 mb-[-1rem] md:mb-[-2.5rem] lg:mb-[-2rem] xl:mb-[-3rem]"
          src="/policy/policy-footer-right-img.webp"
          alt="Background"
        />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
