import React from "react";
import { useNavigate } from "react-router-dom";

function Error404Page() {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/");
  };

  return (
    <div
      className="flex flex-col bg-black justify-center items-center w-screen h-screen"
    >
      {/* Logo */}
      <div className="absolute top-[2rem] xl:top-[4rem] left-[3rem] lg:left-[6rem] xl:left-[12rem]">
        <a className="" href="https://animara.world" target="_blank" rel="noopener noreferrer">
          <img
            src="/icons/animara-logo-white.webp"
            className="w-[30dvw] lg:w-[10dvw]"
            alt="animara icon"
          />
        </a>
      </div>

      {/* Content */}
      <div
        className="w-auto h-[55dvh] xl:h-[70dvh] items-center justify-center bg-contain bg-no-repeat"
        style={{
          backgroundImage: `url("/assets/images/clicker-character/verify-email-content-bg.webp")`,
          backgroundPosition: "center",
        }}
      >
        <div className="h-full flex flex-col justify-center items-center mb-[1rem]">
          <h1 className="w-[70%] text-xl md:text-3xl lg:text-5xl font-semibold text-center text-[#ffa900] mb-[0.5rem] lg:mb-[1rem]">
            Are you lost?
          </h1>
          <p className="w-[90%] text-slate-300 text-sm xl:text-xl text-center font-normal font-outfit ">
            The page you're looking for does not exist.{" "}
            <span
              onClick={handleLoginRedirect}
              className="text-[#00b8e1] font-LuckiestGuy  "
            >
              click here
            </span>{" "}
            to go back home.
          </p>
        </div>

        <div className="flex justify-center items-center"></div>
      </div>
    </div>
  );
}

export default Error404Page;
