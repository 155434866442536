import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCXsrX-2wxsd35d5rKcTfN7zkeW4XVY200",
  authDomain: "moveon-b6ea3.firebaseapp.com",
  databaseURL: "https://moveon-b6ea3-default-rtdb.firebaseio.com",
  projectId: "moveon-b6ea3",
  storageBucket: "moveon-b6ea3.appspot.com",
  messagingSenderId: "269542446082",
  appId: "1:269542446082:web:53fd0253312517702a831b",
  measurementId: "G-WQVCSX6C65",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, app, db, storage };
