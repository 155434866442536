import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  // Define sections with subsections where necessary
  const sections = [
    { id: 1 },
    { id: 2, subSections: [2.1, 2.2] },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
  ];

  return (
    <div className="relative max-w-[1920px] h-auto w-screen overflow-hidden z-[30]">
      {/* Start and End Fade Out Overlays (Shared across Hero and Content) */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      {/* Privacy Policy Hero Section */}
      <div className="relative h-auto w-full z-[30]">
        <div className="h-full w-full flex flex-col justify-center items-center text-center p-[6rem] xl:p-[18rem]"
          style={{
            backgroundImage: 'url("/policy/policy-hero-bg.webp")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <h1 className="text-[#FFC85A] text-3xl lg:text-6xl xl:text-9xl font-normal font-bigNoodle animate-fadeIn mb-[1rem] xl:mb-[2rem]">
            {t("policy title")}
          </h1>
          <p className="text-white text-base lg:text-lg xl:text-xl font-medium font-outfit px-[1rem] lg:px-[5rem] xl:px-[10rem] xl:mb-[2rem] animate-fadeIn">
            {t("policy date")}
          </p>
        </div>
      </div>

      {/* Privacy Policy Content Section */}
      <div className="relative h-full w-full mt-[-22rem] xl:mt-[-28rem] z-[10] text-justify">
        {/* Pseudo-element for background image with 10% opacity */}
        <div
          className="absolute inset-0 z-[-1] opacity-10"
          style={{
            backgroundImage: `url('/policy/policy-content-bg.webp')`,
            backgroundRepeat: 'repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
          }}
        ></div>

        {/* Gradient Background */}
        <div
          className="absolute inset-0 z-[-2]"
          style={{
            backgroundImage: `linear-gradient(to bottom, #003E6B, #000000)`,
          }}
        ></div>
        {/* <img
          className="absolute inset-0 w-full h-auto bg-fill bg-repeat opacity-15 z-[-1]"
          src="/policy/policy-content-bg.png"
          alt="Background"
        /> */}

        <div className="flex flex-col items-start px-[5rem] lg:px-[10rem] xl:px-[20rem] py-[22rem] z-[-1]">
          {/* Content Sections */}
          {sections.map((section) => (
            <div key={section.id} className="flex flex-col mb-[4rem]">
              <h1 className="text-[#49DEFF] text-2xl lg:text-4xl xl:text-6xl font-normal font-bigNoodle mb-[1rem]">
                {section.id}. {t(`policy ${section.id} title`)}
              </h1>
              <p
                className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide"
                dangerouslySetInnerHTML={{
                  __html: t(`policy ${section.id} content`),
                }}
              />

              {/* Handle Subsections */}
              {section.subSections &&
                section.subSections.map((subSection) => (
                  <div key={subSection}>
                    <h2 className="text-[#C5C5C5] text-xl lg:text-2xl xl:text-4xl font-normal font-bigNoodle leading-9 mt-[2rem] mb-[1rem]">
                      {subSection} {t(`policy ${subSection} title`)}
                    </h2>
                    <p
                      className="text-[#C5C5C5] text-sm lg:text-base font-normal font-outfit leading-tight tracking-wide"
                      dangerouslySetInnerHTML={{
                        __html: t(`policy ${subSection} content`),
                      }}
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>

        {/* Footer Images */}
        <img
          className="absolute left-0 bottom-0 w-full lg:w-1/2 mb-[-1rem] md:mb-[-2.5rem] lg:mb-[-2rem] xl:mb-[-3rem]"
          src="/policy/policy-footer-left-img.webp"
          alt="Background"
        />

        <img
          className="absolute right-0 bottom-0 w-full lg:w-1/2 mb-[-1rem] md:mb-[-2.5rem] lg:mb-[-2rem] xl:mb-[-3rem]"
          src="/policy/policy-footer-right-img.webp"
          alt="Background"
        />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
