import React from 'react';

const ChevronRightStyled = ({ width = "68", height = "68", filter = true }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Removed the filter for better iOS compatibility */}
    <rect x="4" width="60" height="60" rx="30" fill="#FFB23F" />
    <rect x="4.2" y="0.2" width="59.6" height="59.6" rx="29.8" stroke="#E59E69" strokeWidth="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.659 15.6276C26.7803 16.4645 26.7803 17.8212 27.659 18.6581L39.568 30L27.659 41.3419C26.7803 42.1788 26.7803 43.5355 27.659 44.3724C28.5377 45.2092 29.9623 45.2092 30.841 44.3724L44.341 31.5152C45.2197 30.6784 45.2197 29.3216 44.341 28.4848L30.841 15.6276C29.9623 14.7908 28.5377 14.7908 27.659 15.6276Z" fill="white" />
  </svg>
);

export default ChevronRightStyled;
