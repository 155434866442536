import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AniPaperHero = () => {
  const { t } = useTranslation();
  // Function to detect iOS
  const isIOS = () => {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative h-[90vh] lg:h-[100vh] w-screen max-w-[1920px] z-[30] flex flex-col items-center justify-center bg-[#000000]">

      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute inset-0 z-[1] flex flex-col w-full h-full items-center justify-center">

        {isIOS() ?
          <img
            src="/anipaper/anipaper-hero-mobile-text.webp"
            className="lg:hidden object-cover h-[55%]"
            preload
            alt="Background"
          />
          :
          <video
            src="https://storage.animara.world/anipaper-hero-mobile.webm"
            className="lg:hidden object-cover h-[55%]"
            autoPlay
            loop
            muted
          />
        }
        <video
          src="https://storage.animara.world/anipaper-hero.webm"
          className="mx-auto hidden lg:block object-fill w-full"
          autoPlay
          loop
          muted
        />
        
        {/* text content */}
        <div className="w-screen flex flex-col items-center justify-center gap-[2rem] mt-[5rem]">
          <p className="w-[80%] lg:w-[50%] text-[#FFFFFF] text-center text-sm font-normal font-outfit transition-opacity duration-1000">
            {formatStringBreaks(t("anipaper hero desc"))}
          </p>
          <a
            type="buttop"
            href="https://app.gitbook.com/o/mN71W3Nq0S0XdNawe4Y3/s/VcGgmU5NDwuBhH6dkgf7"
            target="_blank"
            rel="noreferrer"
            className="px-[2rem] lg:px-[6rem] py-[1rem] bg-[#FFB23F] drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30] hover:scale-105 transition-all duration-300 "
          >
            {formatStringBreaks(t("animara white paper"))}
          </a>
        </div>
      </div>

      {/* floating arrow */}
      {/* <div className="relative h-[26rem] z-[100] flex justify-center items-center pointer-events-none">
        <a 
          href="https://app.animara.world/login"
          target="_blank"
          rel="noreferrer"
          className="absolute top-[85%] lg:top-[56%] left-[20%] lg:left-[33%] transform -translate-x-1/2 -translate-y-1/2 w-[4rem] lg:w-[7rem] z-[999] scale-105 hover:scale-150 transition-all duration-300">
          <img
            className="w-[100%] rotate-infinite bg-[rgb(0,0,0,0.4)] backdrop-blur-[2px] rounded-full z-[200]"
            src="/mainV2/hero-button-cover.webp"
            alt="button cover"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              className="w-[65%] z-[200]"
              src="/mainV2/hero-button-arrow.webp"
              alt="button arrow"
            />
          </div>
        </a>
      </div> */}

      <div className="absolute inset-0 opacity-60 bg-[radial-gradient(circle,_#035D9F_0%,_#000000_80%,_transparent_100%)] lg:bg-[radial-gradient(circle,_#035D9F_0%,_#000000_90%,_transparent_100%)] pointer-events-none bg-[transparent] z-[-1]"></div>

      <div className="absolute opacity-100 w-screen h-[15vh] bottom-0 bg-gradient-to-b from-[transparent] from-0% to-[#000000] to-100% pointer-events-none z-[-1]"></div>      
    </div>
  );
};

export default AniPaperHero;
