import React, { useRef, useState } from 'react';
import MainV2Hero from './MainV2Hero';
import MainV2Marketplace from './MainV2Marketplace';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ToTopButton from '../../component/ToTopButton'; // Add the import here
import MainV2EarnEvent from './MainV2EarnEvent';
import MainV2AniWalk from './MainV2AniWalk';
import MainV2TokenValue from './MainTokenValue';
import MainV2Referal from './MainV2Referal';
import MainV2Supporter from './MainV2Supporter';
import MainV2Footer2 from './MainV2Footer2';
import MainTokenValueMobile from './MainTokenValueMobile';

const MainV2Page = () => {

    const videoRef = useRef(null);
    const [hasEndedOnce, setHasEndedOnce] = useState(false);

    return (
        <div className='relative bg-black flex flex-col items-center justify-center overflow-x-hidden'>
            <Navbar landingVideoEnded={hasEndedOnce} isSticky/>
            <MainV2Hero videoRef={videoRef} hasEndedOnce={hasEndedOnce} setHasEndedOnce={setHasEndedOnce}/>
            <MainV2EarnEvent />
            {/* <MainV2Trailer /> */}
            {/* <MainV2Character /> */}
            <MainV2Marketplace />
            {/* <MainV2ReferalCashback /> */}
            <MainV2AniWalk />
            <MainV2TokenValue />
            <MainTokenValueMobile />
            {/* <MainV2Matter /> */}
            <MainV2Referal />
            <MainV2Supporter />
            <MainV2Footer2 />
            {/* <MainV2Scene /> */}
            {/* <MainV2Roadmap /> */}
            {/* <MainV2Partners /> */}
            {/* <MainV2Footer /> */}
            <Footer />
            <ToTopButton /> {/* Add the button here */}
        </div>
    );
}

export default MainV2Page;
