import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const MainV2Footer2 = () => {
  const { t } = useTranslation();

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative w-full h-full max-w-[1920px] xl:max-h-[1080px] flex items-center justify-center mb-[-0.8rem] lg:mb-[-3.5rem] aqpx-[8rem]">

    {/* Start and End Fade Out Overlays */}
    <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
        <img
          className="hidden lg:flex w-full h-full max-h-[1110px] object-fill pointer-events-none"
          src="/mainV2/main-footer.webp"
          alt="background"
        />
        <img
          className="flex lg:hidden w-full h-full object-cover pointer-events-none"
          src="/mainV2/main-footer-mobile.webp"
          alt="background"
        />

        <div className="absolute top-0 lg:inset-0 mt-[5rem] lg:mt-0 flex flex-col items-center justify-center">
          <h1 className="text-white text-center text-4xl lg:text-6xl font-normal font-bigNoodle transition-opacity duration-1000 mt-[2rem]">
            {formatStringBreaks(t("anifooter title"))}
          </h1>
          <p
            className="mb-6 text-[#FFFFFF] text-center text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000 mt-[1rem] px-6"
          >
            {t("anifooter desc")}
          </p>
          <a 
            type="button"
            href="https://app.animara.world/login"
            className="w-fit px-[2rem] lg:px-[5rem] py-[1rem] bg-[#FFB23F] drop-shadow-lg text-white rounded-full text-base lg:text-lg xl:text-xl font-outfit font-bold transition-all duration-300 hover:scale-105"
          >
            {t("join now")}
          </a>
       </div>
    </div>
  );
};

export default MainV2Footer2;
