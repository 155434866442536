import React, { useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import TermsConditionsContent from './TermsConditionsContent';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ToTopButton from '../../component/ToTopButton';

const TermsConditionsPage = () => {
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <div className='relative bg-black items-center justify-center flex flex-col overflow-x-hidden'>
      <Navbar />
      <TermsConditionsContent />
      <Footer />
      <ToTopButton />
    </div>
  )
}

export default TermsConditionsPage;