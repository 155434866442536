import React, { useEffect, useState } from "react";

const ToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed flex justify-end bottom-[4rem] right-[2rem] lg:right-[6rem] z-[2000] ">
      {isVisible && (
        <img
          src="/icons/to-top-button.webp"
          alt="to top button"
          className="w-[30%] h-auto transition-all duration-300 hover:scale-105"
          onClick={scrollToTop}
        />
      )}
    </div>
  );
};

export default ToTopButton;
