import React from "react";

const FieldScene = ({ index, width, marginTop, onClick, title, gameTitle, description }) => {
  return (
    <div
      onClick={onClick}
      className={`${width} ${marginTop} flex justify-end `}
      style={{ transform: "rotate(3deg)" }}
    >

      <div
        className="w-full lg:w-[60%] xl:w-[80%] h-auto rounded-3xl shadow-stone-500 shadow-md hover:shadow-orange-300 hover:shadow-lg my-[2rem] xl:mx-[3rem]"
        style={{
          backgroundImage: `url('/story/comic-bg.webp')`,
        }}
      >
        <div className="p-[1rem]">
          <div className="relative flex justify-center items-center">
            {gameTitle === "Coming soon" ? (
              <p className="text-center text-xl lg:text-2xl xl:text-3xl text-gray-700 font-bold">
                Coming soon
              </p>
            ) : (
              <img
                src={`/animara/scene/main-game-${index}.webp`}
                alt={`game ${index}`}
                className="h-[25dvh] w-full object-cover rounded-2xl "
              />
            )}
          </div>

          {gameTitle !== "Coming soon" && ( 
            <div className="w-full flex flex-col py-[1rem]">
              <p className="text-[#205B79] text-lg lg:text-2xl xl:text-4xl font-normal font-bigNoodle ml-[1rem]">
                {title}
              </p>
              <p className="text-[#3C3C3C] text-sm lg:text-base xl:text-lg font-normal font-outfit ml-[1rem]">
                {description}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldScene;
