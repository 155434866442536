import React, { useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import PrivacyPolicyContent from './PrivacyPolicyContent';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ToTopButton from '../../component/ToTopButton';

const PrivacyPolicyPage = () => {
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <div className='relative flex flex-col bg-black items-center justify-center overflow-x-hidden'>
      <Navbar isSticky/>
      <PrivacyPolicyContent />
      <Footer />
      <ToTopButton />
    </div>
  )
}

export default PrivacyPolicyPage;