import gsap from "gsap";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const MainV2TokenValue = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const coinRef = useRef(null);
  const textRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    const coin = coinRef.current;
    const text = textRef.current;

    gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        start: "top top",
        end: "bottom bottom-=500",
        scrub: 4,
      }
    })
    .fromTo(coin,
      { y: 500, opacity: 0, force3D: true },
      { y: 0, opacity: 1, duration: 9, ease: "power2.out", force3D: true }
    )
    .fromTo(text,
      { opacity: 0 }, { opacity: 1, duration: 10 }
    )
    .fromTo(content, 
      { y: "100vh" }, { y: "550px", duration: 20 }
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="hidden lg:inline relative w-full max-w-[1920px] overfow-y-hidden">

      {/* Start and End Fade Out Overlays */}
      <div className="hidden xl:block absolute top-0 left-0 w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="hidden xl:block absolute top-0 right-0 w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      
      <div ref={containerRef} className="relative h-[110vh] w-full">
        <div className="absolute inset-0 z-[1] gradient-overlay">
          <img
            className="w-full h-auto object-cover bg-fixed"
            src="/mainV2/tokenvalue-bg.webp"
            alt="Background"
          />
        </div>
        <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none z-[30]">
          {!isMobile ? (
            <video
            ref={coinRef}
            src="https://storage.animara.world/coin-turntable.webm"
            className="w-1/5 h-auto z-200"
            autoPlay
            loop
            muted
            style={{ willChange: 'transform, opacity' }}
          />
          ) : (
            <img
              className="w-2/3 h-auto z-[200]"
              src="/mainV2/coin-turntable.webp"
              alt="Background"
            />
          )}
          
          <div ref={textRef} className="flex flex-col items-center justify-center">
            <h1 className="text-[#FFC85A] text-6xl font-normal font-bigNoodle transition-opacity duration-[3000ms] mt-[2rem]">
              {t('anitoken value title')}
            </h1>
            <p
              className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-[3000ms] mt-[1rem]"
            >
              {formatStringBreaks(t("anitoken value desc"))}
            </p>
          </div>
        </div>
      </div>
      <div ref={contentRef} className="absolute inset-0 flex flex-col h-[100vh] bg-transparent items-center justify-center z-[100]">
        <div className="flex flex-col w-[85%] justify-between">
          <div className="flex flex-col w-[27%] self-start">
            <h1 className="text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 1'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 1'))}
            </p>
          </div>
          <br /><br /><br />
          <div className="flex flex-col w-[27%] self-end">
            <h1 className="text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 2'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 2'))}
            </p>
          </div>
          <br /><br /><br />
          <div className="flex flex-col w-[27%] self-start mt-[5rem]">
            <h1 className="text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 3'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 3'))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainV2TokenValue;
