import React, { createContext, useContext, useEffect, useRef } from 'react';

const AudioContext = createContext();

export const useAudio = () => useContext(AudioContext);

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(new Audio("https://storage.animara.world/page-bgm-looping.mp3"));

  const playMusic = (src) => {
    if (audioRef.current.src !== src) {
      audioRef.current.pause();
      audioRef.current = new Audio(src);
      audioRef.current.loop = true;
      audioRef.current.play().catch((error) => console.log("Audio play failed:", error));
    } else {
      audioRef.current.play().catch((error) => console.log("Audio play failed:", error));
    }
  };

  const stopMusic = () => {
    audioRef.current.pause();
  };

  useEffect(() => {
    const playBackgroundMusic = () => {
      audioRef.current.play().catch((error) => console.log("Audio play failed:", error));
    };

    window.addEventListener("click", playBackgroundMusic, { once: true });
    window.addEventListener("scroll", playBackgroundMusic, { once: true });

    return () => {
      window.removeEventListener("click", playBackgroundMusic);
      window.removeEventListener("scroll", playBackgroundMusic);
    };
  }, []);

  return (
    <AudioContext.Provider value={{ playMusic, stopMusic }}>
      {children}
    </AudioContext.Provider>
  );
};
