import { call, put, takeLatest } from "redux-saga/effects";
import {
  getComicList,
  getComicListSuccess,
  getComicListError,
  getComic,
  getComicSuccess,
  getComicError,
} from "../sagaStore/slices/comicSlice";
import {
  handeleGetStaticComicList,
  handleGetComicData,
} from "../firebase/comic";

export function* getComicListSaga({ payload }) {
  const type = payload;
  const data = yield call(handeleGetStaticComicList, type);
  if (data.length !== 0) {
    yield put(getComicListSuccess(data));
  }
  else {
    yield put(getComicListError(null));
  }
}

export function* getComicSaga({ payload }) {
  const comicId = payload;
  if (comicId) {
    const comicData = yield call(handleGetComicData, comicId);
    yield put(getComicSuccess(comicData));
  } else {
    yield put(getComicError(null));
  }
}

export function* comicSagaWatcher() {
  yield takeLatest(getComicList.type, getComicListSaga);
  yield takeLatest(getComic.type, getComicSaga);
}
