import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronUp from "../../component/icons/ChevronUp";
import ChevronDown from "../../component/icons/ChevronDown";

const AnalyticsPointsMobile = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  const pointList = [
    {
      title: t("ani chart title 1"),
      content: t("ani chart content 1"),
    },
    {
      title: t("ani chart title 2"),
      content: t("ani chart content 2"),
    },
    {
      title: t("ani chart title 3"),
      content: t("ani chart content 3"),
    },
    {
      title: t("ani chart title 4"),
      content: t("ani chart content 4"),
    },
  ];

  const percentageList = ["30%", "50%", "5%", "15%"];

  const amountList = [
    t("3btoken"),
    t("5btoken"),
    t("1.5btoken"),
    t("0.5btoken"),
  ];

  const formatData = (data) => {
    if (!data) return null;
    return data.split("\n").map((item, index) => {
      // Regular expression to identify fully capitalized words
      const regex = /\b([A-Z]{2,})\b/g;

      // Split the line into words and apply different styles/classes
      const formattedContent = item.split(regex).map((word, idx) => {
        if (regex.test(word)) {
          // If the word is fully capitalized, apply different styling
          return (
            <span
              key={idx}
              className="text-[#FFFFFF] text-lg lg:text-xl xl:text-2xl font-normal font-bigNoodle"
            >
              {word}
            </span>
          );
        } else if (word.trim() !== "") {
          // Render non-empty words normally (trim to remove extra spaces)
          return <div className="mb-[2rem]"><span key={idx}>{word}</span></div>;
        } else {
          // Render empty words as empty spans to avoid unintended spaces
          return <span key={idx}></span>;
        }
      });

      return (
        <React.Fragment key={index}>
          <div>{formattedContent}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="relative h-auto w-screen max-w-[1920px] flex flex-col items-center bg-[#000000] mt-[-5px]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="flex lg:hidden flex-col w-[80%] h-auto">
          {pointList.map((item, index) => (
            <div
              key={index}
              className={`h-full my-[1rem] transition-all duration-300 ease-in-out pl-[1rem] border-l-[4px]
              ${expandedIndex === index ? "border-[#205B79]" : "border-[#49DEFF]"}`}
              onClick={() => toggleExpand(index)}
            >
              <div className="flex justify-between items-end h-full">
                <div className="flex flex-col gap-[0.5rem]">
                  <p className="text-[#FFFFFF] text-3xl font-bigNoodle uppercase">
                    {item.title}
                  </p>
                  <div className="flex">
                    <p className="text-[#FFFFFF] text-xl font-bigNoodle">
                      {percentageList[index]}
                    </p>
                    <p className="text-[#FFAA00] text-xl font-bigNoodle ml-[1rem]">
                      {amountList[index]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row ">
                  <button 
                    className={`z-[999] text-1xl font-bold ml-[1rem]  transition-all duration-300 ease-in-out ${expandedIndex === index ? "-rotate-180" : ""}`}
                    onClick={() => toggleExpand(index)}>
                    <ChevronDown fill={"#FFAA00"} width="12px"/>
                  </button>
                </div>
              </div>
              <div className={`${expandedIndex === index ? "opacity-100 h-auto" : "opacity-0 absolute"} transition-opacity duration-300 ease-in-out pt-[1rem] pb-[1rem] text-sm xl:text-lg mt-4 text-[#FFFFFF] font-outfit font-light`}>
                {formatData(item.content)}
              </div>
            </div>
          ))}
      </div>

    </div>
  );
};

export default AnalyticsPointsMobile;
