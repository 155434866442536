import { db } from "../firebase/firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";

const handeleGetStaticComicList = async (type) => {
  try {
    const staticComicQuery = query(
      collection(db, "comic"),
      where("type", "==", parseInt(type)),
      limit(20)
    );

    const comicSnapshot = await getDocs(staticComicQuery);

    const comics = [];
    comicSnapshot.forEach((doc) => {
      if (doc.exists()) {
        comics.push(doc.data());
      }
    });

    return comics;
  } catch (err) {
    console.log(err);
  }
};

const handleGetComicData = async (comicId) => {
  try {
    const comicsRef = collection(db, "comic");
    const q = query(comicsRef, where("comicId", "==", comicId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const comicData = querySnapshot.docs[0].data();
      return comicData;
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log("Error getting comic data: ", error);
  }
};

export { handeleGetStaticComicList, handleGetComicData };
