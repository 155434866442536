// sagaStore/slices/comicSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks/storeHooks';

const comicInitialState = {
  loading: false,
  comicList: [],
  comicData: null,
  error: null,
};

export const comicSlice = createSlice({
  name: 'comic',
  initialState: comicInitialState,
  reducers: {
    getComicList: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    getComicListSuccess: (state, { payload }) => {
      state.loading = false;
      state.comicList = payload;
    },
    getComicListError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getComic: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    getComicSuccess: (state, { payload }) => {
      state.loading = false;
      state.comicData = payload;
    },
    getComicError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetComicList: (state) => {
      state.loading = false;
      state.comicList = [];
      state.comicData = null;
      state.error = null;
    }
  },
});

export const { getComicList, getComicListSuccess, getComicListError, getComic, getComicSuccess, getComicError, resetComicList } = comicSlice.actions;

export const useComicListLoading = () => useAppSelector((state) => state.comic.loading);
export const useComicList = () => useAppSelector((state) => state.comic.comicList);
export const useComicListError = () => useAppSelector((state) => state.comic.error);

export const useComic = () => useAppSelector((state) => state.comic.comicData);
export const useComicLoading = () => useAppSelector((state) => state.comic.loading);
export const useComicError = () => useAppSelector((state) => state.comic.error);

const comicReducer =  comicSlice.reducer; 
export default comicReducer;