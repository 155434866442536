import React from 'react';

const ChevronLeftStyled = ({ width = "68", height = "68", filter = true }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Removed the filter for better iOS compatibility */}
    <rect x="4" width="60" height="60" rx="30" fill="#FFB23F"/>
    <rect x="4.2" y="0.2" width="59.6" height="59.6" rx="29.8" stroke="#E59E69" strokeWidth="0.4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.341 15.6276C41.2197 16.4645 41.2197 17.8212 40.341 18.6581L28.432 30L40.341 41.3419C41.2197 42.1788 41.2197 43.5355 40.341 44.3724C39.4623 45.2092 38.0377 45.2092 37.159 44.3724L23.659 31.5152C22.7803 30.6784 22.7803 29.3216 23.659 28.4848L37.159 15.6276C38.0377 14.7908 39.4623 14.7908 40.341 15.6276Z" fill="white"/>
  </svg>
);

export default ChevronLeftStyled;
