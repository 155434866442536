import React, { useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import AniPaperHero from './AniPaperHero';
import AniPaperRoadmap from './AniPaperRoadmap';

const AniPaperPage = () => {
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <div className='relative bg-black flex flex-col items-center justify-center overflow-x-hidden'>
      <Navbar isSticky/>
      <AniPaperHero />
      <AniPaperRoadmap />
      <Footer />
    </div>
  )
}

export default AniPaperPage;