import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainV2AniWalk = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative h-full max-w-[1920px] max-h-[1080px] py-0 lg:py-[8rem] w-full content-center bg-[#000000]">

    {/* Start and End Fade Out Overlays */}
    <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
      {!isMobile ? (
        <div className="hidden lg:flex absolute top-0 left-0 pointer-events-none gradient-overlay">
          <video
            src="https://storage.animara.world/city-walk.webm"
            className="w-auto h-full"
            autoPlay
            loop
            muted
          />
        </div>
      ) : (
        <div>
            <img
              className="w-full h-full object-cover"
              src="/mainV2/animal-walk.webp"
              alt="Event Background"
            />
          <div className="flex lg:hidden absolute top-[-1px] left-0 h-[56vh] pointer-events-none z-[50]">
            <img
              className="w-full h-full object-cover"
              src="/mainV2/Anicity-walk-bg.webp"
              alt="Event Background"
            />
          </div>
        </div>
      )}
      

      <div className="relative h-full w-full flex flex-col items-start justify-center px-[3rem] lg:px-[10rem] py-[4rem] lg:py-[8rem] gap-[2rem] z-[100]">
        <div className="flex flex-col w-full lg:w-[40%] gap-[0.3rem]">
          <h1 className="text-[#FFC85A] text-5xl lg:text-6xl font-normal font-bigNoodle transition-opacity duration-1000">
            {t('aniwalk title 1')}
          </h1>
          <h1 className="w-fit bg-[#0163BE] py-[0.5rem] px-[2rem] text-[#FFFFFF] rounded-[30px] text-4xl lg:text-5xl font-normal font-bigNoodle transition-opacity duration-1000">
            {t('aniwalk title 2')}
          </h1>
          <h1 className="text-[#FFC85A] text-5xl lg:text-6xl font-normal font-bigNoodle transition-opacity duration-1000">
            {formatStringBreaks(t('aniwalk title 3'))}
          </h1>
        </div>
        <div className="flex flex-col lg:w-[40%] gap-[1.5rem]">
          <p
            className={`text-white text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 1")}
          </p>
          <p
            className={`text-[#C5C5C5] text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 2")}
          </p>
          <p
            className={`text-[#C5C5C5] text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 3")}
          </p>
          <a
            type="button"
            href="https://app.animara.world/login"
            className="w-fit px-[2rem] lg:px-[5rem] py-[1rem] mt-4 bg-[#FFB23F] drop-shadow-lg text-white rounded-full text-base lg:text-lg xl:text-xl font-outfit font-bold transition-all duration-300 hover:scale-105"
          >
            {t("join now")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainV2AniWalk;